/*@import "@angular/material/theming";
 $custom-typography: mat-typography-config(
 $font-family: 'var(--gh-font-family);',
);
@include mat-core($custom-typography);*/
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.mytabsgh {
  padding: 0 !important;
  margin: 0;
  border-bottom: 1px solid #c6c3bd;
}

.mytabsgh>a {
  float: left;
  display: block;
  list-style: none;
  margin: 0;
  border-bottom: 4px solid #f4f4f4;
  padding: 16px 12px;
}

.mytabsgh>a:hover,
.mytabsgh>a.active {
  border-bottom: 4px solid var(--gh-color-dynamic-1);
  color: var(--gh-color-dynamic-1);
}

.card>.textdesc {
  color: var(--gh-color-letter);
  font-size: 16px;
}

.card>.texttitle {
  margin: 0;
  padding: 0;
  font-size: 24px !important;
  color: var(--gh-color-letter);
  font-weight: 600;
  opacity: 1;
}

.card .card-padding {
  padding: 40px 32px !important;
}

.card .card-padding-top {
  padding-top: 32px !important;
}

.card .card-padding-bottom {
  padding-bottom: 32px !important;
}

.card .card-padding-left {
  padding-left: 40px !important;
}

.card .card-padding-right {
  padding-right: 40px !important;
}

.card .card-padding-leftright {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.card .card-margin {
  margin: 40px 32px !important;
}

.card .card-margin-top {
  margin-top: 32px !important;
}

.card .card-margin-bottom {
  margin-bottom: 32px !important;
}

.card .card-margin-left {
  margin-left: 40px !important;
}

.card .card-margin-right {
  margin-right: 40px !important;
}

.card .card-margin-leftright {
  margin-left: 32px !important;
  margin-right: 40px !important;
}

.card .btncreate {
  color: var(--gh-color-dynamic-1);
  font-size: 16px;
  padding-left: 5px;
}

.card .divnodata {
  text-align: center;
  padding-top: 36px;
  padding-bottom: 36px;
}

.card .divnodata>.iconwarning {
  color: var(--gh-color-dark-grey);
  font-size: 24px;
  margin-right: 5px;
}

.card .divnodata>.textnorules {
  color: var(--gh-color-dark-grey);
  font-size: 16px;
}

/* New gavete */
.modal-right {
  display: flex !important;
  flex-direction: column;
  height: 100vh !important;
  z-index: 2 !important;
  top: 0px !important;
  overflow: hidden;
  position: fixed;
  right: 0;
  background-color: var(--gh-color-white, #2C2C2C) !important;
  padding: 0;
  width: 530px;
  max-width: 100%;
  margin: auto;
  border-radius: 2px;
  will-change: top, opacity;
}

.modal-right>.loadingdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.modal-right .basic-content,
.modal-right .full-content {
  flex-grow: 1;
}

.modal-right .basic-content {
  overflow: auto;
}

.modal-right .full-content {
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
}

.modal-overlay {
  z-index: 1 !important;
  display: block;
  opacity: 0.5 !important;
  background: #0000007a !important;
  cursor: pointer;
}

/* New gavete - FIN */

h2 {
  margin: 0;
}

.mygavete {
  padding: 0;
  margin: 0;
  height: 100vh;
  z-index: 992 !important;
}

.mygavete>.modal-content {
  padding: 0;
  margin: 0;
  height: 15vh;
}

.mygavete>.modal-content.modal-body {
  height: 85vh;
}

.mygavete>.modal-content .modal-padding,
.modal-right .modal-padding {
  padding: 40px 32px !important;
}

.mygavete>.modal-content .modal-padding-top,
.modal-right .modal-padding-top {
  padding-top: 32px !important;
}

.mygavete>.modal-content .modal-padding-bottom,
.modal-right .modal-padding-bottom {
  padding-bottom: 32px !important;
}

.mygavete>.modal-content .modal-padding-left,
.modal-right .modal-padding-left {
  padding-left: 40px !important;
}

.mygavete>.modal-content .modal-padding-right,
.modal-right .modal-padding-right {
  padding-right: 40px !important;
}

.mygavete>.modal-content .modal-padding-leftright,
.modal-right .modal-padding-leftright {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.mygavete>.modal-content .modal-margin,
.modal-right .modal-margin {
  margin: 40px 32px !important;
}

.mygavete>.modal-content .modal-margin-top,
.modal-right .modal-margin-top {
  margin-top: 32px !important;
}

.mygavete>.modal-content .modal-margin-bottom,
.modal-right .modal-margin-bottom {
  margin-bottom: 32px !important;
}

.mygavete>.modal-content .modal-margin-left,
.modal-right .modal-margin-left {
  margin-left: 40px !important;
}

.mygavete>.modal-content .modal-margin-right,
.modal-right .modal-margin-right {
  margin-right: 40px !important;
}

.mygavete>.modal-content .modal-margin-leftright,
.modal-right .modal-margin-leftright {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mygavete>.modal-content .mygavete-buttons {
  bottom: 0 !important;
  position: absolute;
  width: 100%;
  padding: 10px 42px 30px 25px;
  border-top: 1px solid var(--gh-color-light-grey-2, #E3E3E3);
  background: var(--gh-color-white, #FFFFFF) !important;
}

.mygavete>.modal-content .mygavete-buttons .button-save:disabled {
  background: var(--gh-color-light-grey-3, #c3c3c3) !important;
  color: var(--gh-color-white, #ffffff) !important;
}

.list-options-with-img {
  display: block;
  margin: 0;
  padding: 0;
}

.list-options-with-img>li {
  list-style: none;
  float: none;
  margin: 10px 0;
  background-color: #ffffff;
  cursor: pointer;
}

.list-options-with-img>li:hover {
  background-color: #c3c3c3;
}

.list-options-with-img>li>.option-icon {
  float: left;
  width: 10%;
  padding: 0;
  margin: 0;
}

.list-options-with-img>li>.option-icon img {
  width: 100%;
}

.list-options-with-img>li>.option-test {
  float: right;
  width: 80%;
  padding: 0;
  margin: 0;
}

.list-options-with-img>li>.option-test h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 4px 0;
}

.list-options-with-img>li>.option-test span {
  font-size: 16px;
  font-weight: normal;
}

.mymodal__base {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99;
  /* Sit on top */
  padding-top: 15%;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.mymodal {
  display: block !important;
  z-index: 100 !important;
  width: 389px;
  height: auto;
  transform: scaleX(1) scaleY(1);
  background: var(--gh-color-white) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  margin: 0 auto;
}

.mymodal__cont {
  width: 100%;
  padding: 0px !important;
  background: var(---ffffff) 0% 0% no-repeat padding-box;
  background: var(--gh-color-white) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 8px #0000001a;
  border-radius: 8px;
  opacity: 1;
}

.texmesage {
  color: var(--gh-color-letter);
  font-size: 24px;
  font-weight: 500;
}

.buttons__cont {
  width: 100%;
  padding: 32px 0px 0px 0px;
  text-align: center;
}

.buttons__cont>label {
  display: block;
}

#bottom-footers {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(--gh-background);
  border-top: 1px solid var(--gh-color-light-grey-2);
}

.bottom-footers__autosave {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 40px !important;
  width: auto !important;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 28px 0px 0px !important;
}

.buttons__cancel {
  width: 126px;
  height: 38px;
  background: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.buttons__cancel__text {
  color: var(--gh-color-dark-grey);
  font-size: 16px;
  font-weight: 600;
  opacity: 1;
}

.buttons__save {
  width: 126px;
  height: 38px;
  background: var(--gh-color-dynamic-1) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.buttons__save__text {
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 600;
  opacity: 1;
}

.disabled-btn-class {
  background: #c3c3c3 !important;
  color: #ffffff !important;
}

.bottom-footers__autosave__icon {
  color: var(--gh-color-dark-grey);
  font-size: 20px;
  margin-right: 4px;
}

.bottom-footers__autosave__text {
  color: var(--gh-color-dark-grey);
  font-size: 14px;
}

.hidden-backgound {
  z-index: 9;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
}

/* Switches */
/* Enabled Off */
.switch label .lever {
  background-color: #999999;
}

.switch label .lever:after {
  background-color: #ffffff;
}

/* Enabled On */
.switch label input[type="checkbox"]:checked+.lever {
  background-color: #a3e1d4;
}

.switch label input[type="checkbox"]:checked+.lever:after {
  background-color: var(--gh-color-dynamic-1);
}

/* Disabled Off */
.switch input[type="checkbox"][disabled]+.lever,
.switch input[type="checkbox"].disabled-switch+.lever {
  background-color: #c3c3c3;
}

.switch label input[type="checkbox"][disabled]+.lever:after,
.switch label input[type="checkbox"].disabled-switch+.lever:after {
  background-color: #f4f4f4;
}

/* Disabled On */
.switch input[type="checkbox"][disabled]:checked+.lever,
.switch input[type="checkbox"].disabled-switch:checked+.lever {
  background-color: #e4f9f8;
}

.switch label input[type="checkbox"][disabled]:checked+.lever:after,
.switch label input[type="checkbox"].disabled-switch:checked+.lever:after {
  background-color: #8bd8c9;
}

/* FIN - Switches */

/* filters svg */
.svg-color-212121 {
  filter: invert(13%) sepia(1%) saturate(1356%) hue-rotate(21deg) brightness(94%) contrast(98%);
}

.svg-color-FD0D1B {
  filter: invert(10%) sepia(94%) saturate(6229%) hue-rotate(353deg) brightness(114%) contrast(98%);
}

.svg-color-FD9726 {
  filter: invert(77%) sepia(23%) saturate(6787%) hue-rotate(341deg) brightness(101%) contrast(98%);
}

.svg-color-FFCC21 {
  filter: invert(91%) sepia(30%) saturate(3593%) hue-rotate(337deg) brightness(101%) contrast(102%);
}

.svg-color-0A23FB {
  filter: invert(14%) sepia(100%) saturate(7417%) hue-rotate(243deg) brightness(93%) contrast(117%);
}

.svg-color-FD28FC {
  filter: invert(30%) sepia(77%) saturate(5486%) hue-rotate(287deg) brightness(114%) contrast(102%);
}

.svg-color-969696 {
  filter: invert(63%) sepia(2%) saturate(33%) hue-rotate(314deg) brightness(93%) contrast(96%);
}

.svg-color-A51C0E {
  filter: invert(17%) sepia(79%) saturate(2679%) hue-rotate(353deg) brightness(92%) contrast(99%);
}

.svg-color-E69239 {
  filter: invert(63%) sepia(35%) saturate(907%) hue-rotate(347deg) brightness(95%) contrast(89%);
}

.svg-color-F4B92A {
  filter: invert(62%) sepia(90%) saturate(385%) hue-rotate(2deg) brightness(104%) contrast(93%);
}

.svg-color-1AB394 {
  filter: invert(55%) sepia(24%) saturate(6502%) hue-rotate(133deg) brightness(100%) contrast(80%);
}

.svg-color-3C74D6 {
  filter: invert(38%) sepia(99%) saturate(576%) hue-rotate(185deg) brightness(90%) contrast(90%);
}

.svg-color-674EA6 {
  filter: invert(37%) sepia(7%) saturate(5754%) hue-rotate(218deg) brightness(85%) contrast(83%);
}

.svg-color-A74F79 {
  filter: invert(34%) sepia(4%) saturate(7455%) hue-rotate(281deg) brightness(108%) contrast(76%);
}

.svg-color-6AA84E {
  filter: invert(58%) sepia(21%) saturate(5200%) hue-rotate(74deg) brightness(107%) contrast(45%);
}

.svg-color-9825FB {
  filter: invert(22%) sepia(93%) saturate(2715%) hue-rotate(264deg) brightness(93%) contrast(113%);
}

.svg-color-404040 {
  filter: invert(23%) sepia(4%) saturate(24%) hue-rotate(360deg) brightness(98%) contrast(92%);
}

.svg-color-5B0F04 {
  filter: invert(14%) sepia(30%) saturate(3389%) hue-rotate(341deg) brightness(95%) contrast(111%);
}

.svg-color-B25D16 {
  filter: invert(32%) sepia(95%) saturate(509%) hue-rotate(348deg) brightness(107%) contrast(92%);
}

.svg-color-BF8F1F {
  filter: invert(54%) sepia(77%) saturate(463%) hue-rotate(4deg) brightness(92%) contrast(88%);
}

.svg-color-264F13 {
  filter: invert(23%) sepia(11%) saturate(4934%) hue-rotate(63deg) brightness(88%) contrast(85%);
}

.svg-color-1B4682 {
  filter: invert(22%) sepia(84%) saturate(638%) hue-rotate(181deg) brightness(98%) contrast(101%);
}

.svg-color-1F124D {
  filter: invert(5%) sepia(69%) saturate(7317%) hue-rotate(257deg) brightness(70%) contrast(94%);
}

.svg-color-4B102F {
  filter: invert(9%) sepia(95%) saturate(1466%) hue-rotate(301deg) brightness(94%) contrast(99%);
}

.svg-color-000000 {
  filter: invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg) brightness(102%) contrast(102%);
}

.svg-color-EF9A9A {
  filter: invert(92%) sepia(17%) saturate(2329%) hue-rotate(297deg) brightness(106%) contrast(87%);
}

.svg-color-FFCC80 {
  filter: invert(99%) sepia(91%) saturate(4531%) hue-rotate(304deg) brightness(106%) contrast(105%);
}

.svg-color-FDF5CC {
  filter: invert(86%) sepia(5%) saturate(2043%) hue-rotate(339deg) brightness(112%) contrast(106%);
}

.svg-color-C7DAFA {
  filter: invert(82%) sepia(12%) saturate(910%) hue-rotate(190deg) brightness(104%) contrast(96%);
}

.svg-color-C5E1A5 {
  filter: invert(91%) sepia(5%) saturate(1782%) hue-rotate(41deg) brightness(94%) contrast(94%);
}

.svg-color-E8D2DC {
  filter: invert(91%) sepia(20%) saturate(248%) hue-rotate(293deg) brightness(92%) contrast(98%);
}

.svg-color-E3E3E3 {
  filter: invert(100%) sepia(1%) saturate(1452%) hue-rotate(275deg) brightness(109%) contrast(78%);
}

.svg-color-E06767 {
  filter: invert(62%) sepia(19%) saturate(5367%) hue-rotate(321deg) brightness(99%) contrast(77%);
}

.svg-color-E42527 {
  filter: invert(25%) sepia(52%) saturate(4260%) hue-rotate(342deg) brightness(88%) contrast(103%);
}

.svg-color-F8B36B {
  filter: invert(80%) sepia(62%) saturate(688%) hue-rotate(313deg) brightness(108%) contrast(94%);
}

.svg-color-FED865 {
  filter: invert(88%) sepia(18%) saturate(1421%) hue-rotate(336deg) brightness(103%) contrast(104%);
}

.svg-color-6AA84E {
  filter: invert(66%) sepia(10%) saturate(2198%) hue-rotate(57deg) brightness(89%) contrast(82%);
}

.svg-color-A3C2F4 {
  filter: invert(74%) sepia(48%) saturate(2992%) hue-rotate(192deg) brightness(117%) contrast(91%);
}

.svg-color-C4B1F7 {
  filter: invert(83%) sepia(41%) saturate(2186%) hue-rotate(195deg) brightness(96%) contrast(101%);
}

.svg-color-E2D9FF {
  filter: invert(82%) sepia(16%) saturate(785%) hue-rotate(206deg) brightness(103%) contrast(102%);
}

.svg-color-FFA5D0 {
  filter: invert(98%) sepia(84%) saturate(3258%) hue-rotate(281deg) brightness(98%) contrast(105%);
}

.svg-color-969696 {
  filter: invert(69%) sepia(0%) saturate(2478%) hue-rotate(345deg) brightness(86%) contrast(90%);
}

.svg-color-FF8600 {
  filter: invert(55%) sepia(78%) saturate(2560%) hue-rotate(1deg) brightness(103%) contrast(105%);
}

.svg-color-FFC50F {
  filter: invert(88%) sepia(20%) saturate(4656%) hue-rotate(345deg) brightness(101%) contrast(105%);
}

.svg-color-1BB394 {
  filter: invert(52%) sepia(99%) saturate(349%) hue-rotate(118deg) brightness(91%) contrast(91%);
}

.svg-color-589AFF {
  filter: invert(56%) sepia(13%) saturate(5877%) hue-rotate(195deg) brightness(101%) contrast(101%);
}

.svg-color-B45FFF {
  filter: invert(69%) sepia(73%) saturate(6119%) hue-rotate(240deg) brightness(99%) contrast(104%);
}

.svg-color-FF4EA3 {
  filter: invert(55%) sepia(99%) saturate(3519%) hue-rotate(304deg) brightness(102%) contrast(101%);
}

.svg-color-BF8F1F {
  filter: invert(61%) sepia(10%) saturate(6872%) hue-rotate(11deg) brightness(95%) contrast(76%);
}

.cont__other {
  background: #e3e3e3;
  width: 150%;
  position: relative;
  left: -25px;
  bottom: -20px;
  border-top: 1px solid #999999;
  padding-top: 32px;
}

.div__second__cont {
  margin-left: 28px;
  position: relative;
  top: -20px;
}

.pen__butt:focus {
  background-color: #ed5565 !important;
}

/* Footer */
.bott__footer {
  height: 59px;
}

.bottom-footers__autosave {
  margin-top: 16px !important;
  padding: 0px 0px 0px 11px !important;
}

.buttons__save {
  width: auto !important;
}

.buttom__footer {
  display: flex;
  align-items: center;
  margin-top: 8px;
  position: absolute;
  width: auto !important;
  right: 0 !important;
}

.buttons__cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.buttons__cancel:hover {
  box-shadow: none;
  background-color: transparent;
  border: none;
}

.buttons__cancel:hover {
  box-shadow: none !important;
  background-color: transparent !important;
  border: none !important;
}

.buttons__cancel:focus {
  box-shadow: none !important;
  background-color: transparent !important;
  border: none !important;
}

.buttons__cancel:active {
  box-shadow: none !important;
  background-color: transparent !important;
  border: none !important;
}

.buttons__cancel:disabled {
  box-shadow: none !important;
  background-color: transparent !important;
  border: none !important;
}

.buttons__save {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-right: 28px;
}

.buttons__save[disabled] {
  color: var(--gh-color-white, #FFF) !important;
  background-color: var(--gh-color-light-grey-3) !important;
}

.buttons__save {
  width: auto !important;
}

.buttons__save--didebled:focus {
  background-color: #2ab7a9 !important;
}

/* FIN Footer */
.mod__new__sett {
  padding: 21px 0px 0px 24px !important;
}

.cont__message--attention {
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 0px;
  text-align: left;
}

.text_-message {
  font-size: 16px !important;
  color: var(--gh-color-letter);
}

.cont__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1;
  margin-right: 1rem;
}

.button--return {
  background: transparent;
  border: none;
  box-shadow: none;
  margin-left: 20px;
  margin-right: 12px !important;
}

.button--go {
  background: var(--gh-color-dynamic-1) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: none;
  opacity: 1;
}

.button--delete {
  background: #ff4855 0% 0% no-repeat padding-box;
}

.button--go:hover {
  box-shadow: none !important;
}

.button--go>.text--delete {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-transform: initial;
}

.text--return {
  font-size: 14px;
  font-weight: 600;
  color: var(--gh-color-dark-grey);
  text-transform: initial;
}

.text__title {
  font-size: 16px !important;
  font-weight: 600;
  color: var(--gh-color-letter);
}

@media only screen and (min-width: 992px) {
  .text--return {
    font-size: 16px;
  }

  .text--delete {
    font-size: 16px;
  }

  .text__title {
    font-size: 24px;
  }

  .modal__icon-close {
    font-size: 24px !important;
    color: var(--gh-color-dark-grey);
  }

  .text_-message {
    font-size: 16px;
  }
}

.my-label-requests {
  display: block;
  padding: 10px 15px;
  background: #d5d5d5 0% 0% no-repeat padding-box;
}

.my-label-requests h2 {
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: Semibold;
  font-size: 16px !important;
  color: #fff; //#1C84C6;
  text-transform: uppercase;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: inherit !important;
}



.gh__validation:focus {
  border-bottom: 1px solid var(--gh-color-dynamic-1) !important;
}

.gh__validation {
  border-bottom: 1px solid var(--gh-color-dark-grey) !important;
}

.requeride {
  border-bottom: 1px solid var(--gh-color-red) !important;
  -webkit-box-shadow: 0 1px 0 0 var(--gh-color-red) !important;
  box-shadow: 0 1px 0 0 var(--gh-color-red) !important;
}

.requeride:focus {
  border-bottom: 1px solid var(--gh-color-red) !important;
  -webkit-box-shadow: 0 1px 0 0 var(--gh-color-red) !important;
  box-shadow: 0 1px 0 0 var(--gh-color-red) !important;
}



.label__err {
  color: var(--gh-color-red) !important;
}

.label__global {
  color: var(--gh-color-dark-grey);
  font-size: 12px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.label__global.active {
  color: var(--gh-color-dynamic-1);
}

.labelSwitchDisable {
  color: var(--gh-color-dark-grey);
}

.white {
  background-color: var(--gh-color-white, #2C2C2C) !important;
}

.semi-bold {
  font-weight: 600;
}

.btn_none,
.btn_none:hover {
  border: 0;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn_none:focus {
  background: transparent;
}